/***********************************/
/* MODAL */
/***********************************/
#myModal {
  background-color: rgba(0,0,0,0.4);
  padding: 0px 0px 10px 0px;

  &.not-first-step {
    background-color: #fefefe;
    padding: 0px;
  }

  #myModal-container {
    background-color: #fefefe;
    margin: 0px auto;
    padding: 0px 20px 20px 20px;
    border: 1px solid #888;
    border-radius: 10px;
    width: 80%;
    max-width: 500px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    text-align: center;

    &.not-first-step {
      border: none;
      box-shadow: none;

      #addressValidatorBox {
        margin-top: 0px;
      }
    }
  }
}


/***********************************/
/* PROMPTS */
/***********************************/
.preview-header-modal {
  background-color: rgba(0,0,0,0.4);
  color: white;
}
#addressValidatorBox {
  border: 2px solid;
  border-radius: 10px;
  color: black;
  margin-top: 20px;
  padding: 10px;
  text-align: center;

  h2 {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 0px;
    margin-top: 0px;
  }

  small {
    font-size: 12.25px;
    line-height: 18.2px;
  }

  br {
   line-height: 18.2px; 
  }

  div#suggestedAddress, div#originalAddress {
    cursor: pointer;
    padding: 10px;

    span, b {
      font-size: 14px;
      line-height: 18.2px;
    }

    span.changed {
      font-weight: bold;
    }

    b {
      font-weight: 500px;
    }
  }
}



@media (max-width: 550px) {
  #myModal.not-first-step {
    #myModal-container.not-first-step {
      width: 100%;
      padding: 0px 0px 10px 0px;
    }
  }
}



/***********************************/
/* BUTTONS */
/***********************************/
.btn-row {
  margin-top: 10px;

  .av_button {
    background-color: white;
    color: black;
    border: 2px solid black;
    border-radius: 10px;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 10px 10px 0px 10px;
    cursor: pointer;
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
    z-index: 1000;
  }
}


/***********************************/
/* LOADER */
/***********************************/
#loader-text {
  margin-top: 20px;
  margin-bottom: 20px;
  color: black;
  font-size: 16px;
  font-weight: bold;
}
#loader-spinner {
  margin: 0px auto;
  border: 16px solid #f3f3f3;
  border-top: 16px solid #377dff;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
 
 
/***********************************/
/* LOCATION OF PROMPTS BOX */
/***********************************/
/* Hover styles on radio options */
.radio-option:hover > span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
  color: #196eff; 
}
.radio-option > span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1:hover {
  color: #196eff; 
}



